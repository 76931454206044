import React, { useRef, useEffect } from 'react'
//iro.js.org/introduction.html for doc on this llibrary
import iro from '@jaames/iro'

import './colorSelectThemeButton.scss'

const ColorWheel = ({
    setModal,
    modalShow,
    onChange,
    width: wheelWidth = 350,
    color: defaultColor,
    callback
  }) => {
  const colorWheel = useRef()
  const timeout = useRef()

  useEffect(() => {
    //fixes a bug where remounts will create another color wheel
    if (colorWheel.current.children.length >= 1)
      colorWheel.current.innerHTML = ''
    
    const wheelPicker = new iro.ColorPicker(colorWheel.current, {
      id: 'color-wheel',
      width: wheelWidth,
      color: defaultColor,
      display: 'span',
      padding: 0,
      layout: [{
        component: iro.ui.Slider,
        options: {
          sliderType: 'hue'
        }
      }],
      wheelDirection: 'clockwise',
      borderWidth: 1,
      sliderSize: 30,
      handleRadius: 12,
      layoutDirection: 'horizontal'
    })

    wheelPicker.on(['color:change'], color => {
      if (timeout.current !== null)
        timeout.current = null
      
      //sets document.body.style.setProperty on the async stack
      timeout.current = setTimeout(() => {
        onChange(color, () => callback && callback('wheel'))
      }, 150)
    })

  }, []) /* eslint-disable-line */

  //checks whether click is outside the wheel
  const closeHandler = (e) => {
    if (e.target !== colorWheel.current) {
      setModal(false)
    }
  }

  //uses css instead of onMount method for show so we don't have to instantiate another ColorPicker object
  return (
    <div className={modalShow ? 'color-wheel_modal show' : 'color-wheel_modal hide'} onClick={closeHandler}>
      <div ref={colorWheel} onClick={e => e.stopPropagation()}>

      </div>
    </div>
  )
}

export default ColorWheel