import axios from 'axios';
import {
  CHECK_AUTH_STATUS_ENDPOINT,
  LOGOUT_ENDPOINT,
} from '../constants/secrets';
import Firebase from '../services/firebase'
import cookie from 'cookie';

// const login = ({}) => {}

const logout = async () => {
  Firebase.auth().currentUser && await Firebase.auth().signOut();
  await axios({
    url: LOGOUT_ENDPOINT,
    method: 'GET',
    withCredentials: true,
  })
}
const isLoggedIn = () => !!Firebase.auth().currentUser;

const getUserDetails = () => Firebase.auth().currentUser;

const getJwtToken = async () => Firebase.auth().currentUser && await Firebase.auth().currentUser.getIdToken(true);

const checkStatus = async () => {
  try {
    const parsedCookie = cookie.parse(document.cookie);
    const {
      csst
    } = parsedCookie;
    if (!csst) {
      await logout();
      return false;
    }
    const response = await axios({
      url: CHECK_AUTH_STATUS_ENDPOINT,
      headers: {
        authorization: `Bearer ${csst}`
      },
      withCredentials: true,
    });
    const {
      status = false, frbToken = null
    } = response.data;
    if (!status || !frbToken)
      return false;
    await Firebase.auth().signInWithCustomToken(frbToken)
  } catch (e) {
    console.error(e)
    return false;
  }
  return true;
}

export {
  isLoggedIn,
  // login,
  logout,
  getUserDetails,
  getJwtToken,
  checkStatus,
}