import React from 'react'
import { changeDocumentTheme } from './changeDocumentTheme'
import './colorSelectThemeButton.scss'

/**
 * Color button component
 * @param {object} props - 'purple' | 'tomato' // use props.active for this.state 
 * @param {string} props.color 'purple' | 'tomato' 
 * @param {string} props.active this.state (shows outline if active)
 */
const ColorSelectThemeButton = ({ color, active, changeTheme, setColorWheel, wheel }) => {

  const theme = {
    wheel: 'color-wheel',
    purple: 'rgba(87, 20, 172, 1)',
    tomato: 'rgba(234, 59, 39, 1)'
  }
  
  const containerStyle = {
    border: active === color && `3px #707070 solid`,
  }

  const buttonStyle = {
    background: color !== 'wheel' && theme[color],
  }

  return (
    <div style={containerStyle} className='color-select-button__container'>
      <button 
        style={buttonStyle}
        className={color === 
          'wheel' ? 'color-select-button wheel' : 'color-select-button color'}
        aria-label='change theme color buttons'
        onClick={e => {
          e.preventDefault()
          if (color === 'wheel') {
            setColorWheel()
          } else {
            changeTheme(color)
            changeDocumentTheme(color)
          }
        }}
      >
        {
          color === 'wheel' && <img src={wheel} alt='color-wheel' />
        }
      </button>
    </div>
  )
}

export {
  ColorSelectThemeButton
}