import React from 'react';
import { Container, Col, Spinner } from 'react-bootstrap';
import logo from '../../../assets/images/brand/naya_logo_symbol.png';
import wordmark from '../../../assets/images/brand/naya_logo_wordmark.png';
import Input from '../input/Input';
import LinksSection from '../links-section/LinksSection';
import {checkIfUserExists, isLoggedIn} from '../../../actions/authenticationActions';
import firebase from '../../../services/firebase';

class ForgotPassword extends React.Component{
  state = {
    loading: false, 
    errorMessage: null,
    successMessage: null,
    email:'',
    password:''
  }

  setLoading = (value) => {
    this.setState({loading:value})
  }

  setErrorMessage = (value) => {
    this.setState({errorMessage: value})
  }

  setSuccessMessage = (value) => {
    this.setState({successMessage: value})
  }

  onChange = (e) => {
    this.setSuccessMessage(null);
    this.setErrorMessage(null);
    this.setState({[e.target.name]: e.target.value})
  }

  /* check if user is loggedin */
  checkLogin = () => {
    return isLoggedIn();
  }

  onSubmit = async(e) => {
    e.preventDefault();
    this.setLoading(true);
    const { email } = this.state;
    /* check if the email and password is entered by the user */
    if (email.trim().length===0){
      this.setErrorMessage('Email must be set.');
    }
    else{
      const response = await checkIfUserExists({email})
      if(response.data.status){
        firebase.auth().sendPasswordResetEmail(email)
          .then((user) => {
            this.setSuccessMessage("Reset password link sent successfully. Please check your email.");
            this.setState({email:''})
            this.setLoading(false);
          }).catch((e) => {
            this.setErrorMessage("Reset password link could not be sent. Please try again.");
            this.setLoading(false);
          })
      }
      else{
        this.setErrorMessage("We can't find an account associated with that email. Please sign up.");
        this.setLoading(false);
      }
    }
  }

  /* function to render login form */
  renderFormContent = () => {
    const {email} = this.state;
    return (
      <>
      <Input
        id="email"
        value={email}
        placeholder={"Email"}
        type="email"
        name="email"
        onChange={this.onChange}
      />
      <button className="sign-in-button">{this.state.loading ? this.renderLoader() : 'Reset Password'}</button>
      </>
    )
  }

  /* function to render naya logo */
  renderLogo = () => (
    <Col xs={12} className="logo-container">
      <Col xs={{ span: 4, offset: 4 }}>
        <img src={logo} alt="logo" className="logo fluid" />
      </Col>
      <Col xs={{ span: 8, offset: 2 }}>
        <img src={wordmark} alt="wordmark" className="wordmark fluid" />
      </Col>
    </Col>
  )

  /* function to render necessary messages - loader, error msg, etc */
  renderMessages = () => {
    const {errorMessage, successMessage} = this.state;
    return(
      <>
      {
        errorMessage && (
          <div className="error-message">
            {errorMessage}
          </div>
        )
      }
      {
        successMessage && (
          <div className="success-message">
            {successMessage}
          </div>
        )
      }
      </>
    )
  }

  /* function renders three links:
  1. toggles to login
  2. Toggles to signup form
   */
  renderLinksSection = () => {
    return(
      <LinksSection>
        <a href="/" onClick={(e)=> {
          e.preventDefault();
          this.props.onLoginLinkClick();
        }}>
          Back to login
        </a>
        <div>
          Don’t have an account ? {' '}
          <span
            className="link"
            onClick={()=>this.props.onSignupLinkClick()}
          >
            Sign up
          </span>
        </div>
      </LinksSection>
    )
  }

  renderLoader = () => (
    <div>
        <Spinner animation="grow" size="sm" className="loader"/>
        <Spinner animation="grow" size="sm" className="loader"/>
        <Spinner animation="grow" size="sm" className="loader"/>
      </div>
  )

  render(){
    return(
      <Container>
        <Col xs={12} className='form-container'>
          <form className='login-form col-11' onSubmit={this.onSubmit}>
            {
              this.renderLogo()
            }
            <h6 style={{marginBottom:'2rem', textAlign:'center'}}>Enter your email to send the reset password link</h6>
            {
              this.renderMessages()
            }
            {
              this.renderFormContent()
            }
            {
              this.renderLinksSection()
            }
          </form>
        </Col>
      </Container>
    )
  }
}

export default ForgotPassword;