import React from 'react';
import { Container, Col, Spinner } from 'react-bootstrap';
import logo from '../../../assets/images/brand/naya_logo_symbol.png';
import wordmark from '../../../assets/images/brand/naya_logo_wordmark.png';
import Input from '../input/Input';
import LinksSection from '../links-section/LinksSection';
import { FRONTEND_ENDPOINT } from '../../../constants/secrets';
import { signInWithEmailAndPassword, signInWithPopup, isLoggedIn, signInWithProvider } from '../../../actions/authenticationActions';
import './LoginForm.scss';

class LoginForm extends React.Component{
  state = {
    loading: false, 
    errorMessage: null,
    email:'',
    password:''
  }

  setLoading = (value) => {
    this.setState({loading:value})
  }

  setErrorMessage = (value) => {
    this.setState({errorMessage: value})
  }

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  /* check if user is loggedin */
  checkLogin = () => {
    return isLoggedIn();
  }

  onSubmit = async(e) => {
    e.preventDefault();
    const { email, password } = this.state;
    /* check if the email and password is entered by the user */
    if (!email.trim() || !password.trim())
      return this.setErrorMessage('Email/Password must be set.');

    this.setLoading(true);
    this.setErrorMessage(null);
    try{
      await signInWithEmailAndPassword(email, password);
      await signInWithProvider()
    }
    catch(error){
      this.setLoading(false);
      this.setErrorMessage(error.message)
    }
    /* if logged in, executing the functionality to be completed once the user log in*/
    if(!this.state.errorMessage){
      if(this.checkLogin()){

        this.props.afterLoginSuccess();
      }
      this.setLoading(false);
    }
  }

  renderLoader = () => (
    <div>
        <Spinner animation="grow" size="sm" className="loader"/>
        <Spinner animation="grow" size="sm" className="loader"/>
        <Spinner animation="grow" size="sm" className="loader"/>
      </div>
  )

  /* function to render login form */
  renderLoginFormContent = () => {
    const {email, password} = this.state;
    return (
      <>
      <Input
        id="email"
        value={email}
        placeholder={"Email"}
        type="email"
        name="email"
        onChange={this.onChange}
      />
      <Input
        id="password"
        placeholder={"Password"}
        value={password}
        type="password"
        name = "password"
        onChange={this.onChange}
      />
      <button className="sign-in-button">{this.state.loading ? this.renderLoader() : 'Login'}</button>
      </>
    )
  }

  /* function to render naya logo */
  renderLogo = () => (
    <Col xs={12} className="logo-container">
      <Col xs={{ span: 4, offset: 4 }}>
        <img src={logo} alt="logo" className="logo fluid" />
      </Col>
      <Col xs={{ span: 8, offset: 2 }}>
        <img src={wordmark} alt="wordmark" className="wordmark fluid" />
      </Col>
    </Col>
  )

  /* function to render necessary messages - loader, error msg, etc */
  renderMessages = () => {
    const {showSubmitMessage} = this.props;
    const {loading, errorMessage} = this.state;
    return(
      <>
      {
        showSubmitMessage && (
          <Col xs={12}>
            <p style={{"textAlign":"center", "fontFamily":"rand bold"}}>Please login or sign up to submit your idea</p>
          </Col> 
        )
      }
      {
        loading && (
          <div>
            <p className="loading">
            </p>
          </div>
        )
      }
      {
        errorMessage && (
          <div className="error-message">
            {errorMessage}
          </div>
        )
      }
      </>
    )
  }

  /* function renders three links:
  1. ecosystem login
  2. Toggles to signup form
  3. forgot password
   */
  renderLinksSection = () => {
    return(
      <LinksSection>
        <div>
          <a
            className="link-ecosystem" 
            href={`${FRONTEND_ENDPOINT}/login-ecosystem`}
            target="_blank"
            rel='noopener noreferrer'
          >
            Are you a designer/maker?
          </a>
        </div>
        <div>
          Don’t have an account ? {' '}
          <span
            className="link"
            onClick={this.props.onSignupLinkClick}
          >
            Sign up
          </span>
        </div>
        <a href="/" onClick={(e)=> {
          e.preventDefault();
          this.props.onForgotPasswordLinkClick();
        }}>
          Forgot Password?
        </a>
      </LinksSection>
    )
  }

  /* function to render google and facebook login */
  renderSocialSignIn = () => {
    return(
      <>
        <button className="sign-in-button" onClick={async() => {
            await signInWithPopup("google");
            if(this.checkLogin){
              this.props.afterLoginSuccess();
            }
          }}
        >Sign In with Google</button>
        <button className="sign-in-button"	onClick={async() => {
            signInWithPopup("facebook");
            if(this.checkLogin){
              this.props.afterLoginSuccess();
            }
          }}
        >Sign In with Facebook</button>
      </>
    )
  }

  render(){
    return(
      <Container>
        <Col xs={12} className='form-container'>
          <form className='login-form col-11' onSubmit={this.onSubmit}>
            {
              this.renderLogo()
            }
            {
              this.renderMessages()
            }
            {
              this.renderLoginFormContent()
            }
            {
              this.renderLinksSection()
            }
            {
              this.renderSocialSignIn()
            }
          </form>
        </Col>
      </Container>
    )
  }
}

export default LoginForm;