import React from 'react';

const LinksSection = ({children}) =>
	<div 
		className="links-section"
	>
		{children}
		<div			
			className="seperation-line"
		/>
	</div>

export default LinksSection;