import React from 'react';

const Input = ({ id, value, placeholder, type = 'text', onChange, containerClasses = [], classes = [], required = true, name }) =>
	<div className={["input", ...containerClasses].join(" ")}>
		<input
			id={id}
			value={value}
			placeholder={placeholder}
			type={type}
			onChange={onChange}
			className={classes.join(' ')}
			required={required}
      name={name}
		/>
		<span 
		className="astersik"
		>
			*
		</span>
	</div>
export default Input;