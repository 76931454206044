import axios from 'axios';
import Firebase from '../services/firebase';
import cookie from 'cookie'

import {LOGIN_ENDPOINT, CHECK_AUTH_STATUS_ENDPOINT, LOGOUT_ENDPOINT, GET_USER_ENDPOINT, PROVIDER_LOGIN_ENDPOINT, CHECK_IF_USER_EXISTS} from '../endpoints/authenticationEndpoints';

export const GoogleAuthProvider = new Firebase.auth.GoogleAuthProvider();
export const FacebookAuthProvider = new Firebase.auth.FacebookAuthProvider();


export const signInWithEmailAndPassword = async (email, password) => {
  return await Firebase.auth().signInWithEmailAndPassword(email, password);
}

export const signUpWithEmailPassword = async (email, password) => {
  const res = await Firebase.auth().createUserWithEmailAndPassword(email, password);

  return res
}

/**
 * Function that sends a request to backend upon successful sign in on frontend through one of auth providers to add new user to mongo.
 *
 * @returns response which sets __session and csst cookies
 */
export const signInWithProvider = async ({
    name = '',
    last_name = ''
  } = { name: '', last_name: ''}) => 
{
  if(Firebase.auth().currentUser)
  {
    let {email, providerData} = Firebase.auth().currentUser
    let {providerId, uid} = providerData[0]
    let external_type = providerId.split(".")[0]
    let external = uid

    const url = PROVIDER_LOGIN_ENDPOINT;
    const data = {email, external, external_type, name, last_name}
    try {
      const response = await axios({
        url, 
        data, 
        method: 'POST',
        withCredentials: true
      })
      return {status: true, data: response.data}

    } catch (error) {
      const {message = "Error in signInWithProvider method" } = error
      return {status: false, message}
    }
  }
  else
  {
    const message = "User is not signed in"
    return {status: false, message}
  }
}


/**
 * Custom function to initiate a firebase provider popup. For ex: Google, Facebook etc
 * @param {*} provider_name - name of auth provider to use
 * @returns 
 */
export const signInWithPopup = async (provider_name) => {
  let provider = null

  /* Change provider based on provider name */
  switch(provider_name)
  {
    case "google":
      provider = GoogleAuthProvider
      break
    case "facebook":
      provider = FacebookAuthProvider
      break
    default:
      provider = null
  }
  
  /* Use firebase to sign in */
  try {

    await Firebase.auth().signInWithPopup(provider)
    await signInWithProvider()
    return {
      status: true
    }
  } catch (error) {
      const {errorMessage = "Error in signInWithPopup method" } = error
      return {
        status: false, 
        message: errorMessage
      }
  }
}


/**
 * Check if user is logged in
 * @returns true if user is logged in, false otherwise
 */
export const isLoggedIn = () => {
  /* If Firebase has a user, that means the user is logged in */
  // console.log(!!Firebase.auth().currentUser)
  // console.log(Firebase.auth().currentUser)
  
  return !!Firebase.auth().currentUser;
}


export const getUser = async () => {
  if(Firebase.auth().currentUser)
  {
    /* Extract CSST cookie */
    const parsedCookie = cookie.parse(document.cookie);

    const { csst = null } = parsedCookie;
    /* If there is no CSST cookie, return false */
    if (!csst) {
      // await logout();
      return {status: false, user: null, message: "Could not find csst cookie"}
    }

    const response = await axios({ 
      url             : GET_USER_ENDPOINT,
      data            : {email: Firebase.auth().currentUser.email, uid: Firebase.auth().currentUser.uid},
      method          : 'POST',
      headers         : {
                          authorization: `Bearer ${csst}`
                        },
      withCredentials : true,
    });
    return response.data
  }
  
  return {status: false, user: null}
}


/**
 * Uses the session and csst cookies to get a firebase custom token from backend. Uses custom token to sign user in through Firebase
 * @returns true, if user is successfuly logged in, false otherwise
 */
export const checkStatus = async () => {
    if(!isLoggedIn())
    { 
      try {
        /* Extract CSST cookie */
        const parsedCookie = cookie.parse(document.cookie);
        // console.log(parsedCookie)

        const { csst = null } = parsedCookie;
        /* If there is no CSST cookie, return false */
        if (!csst) {
          // await logout();
          return false;
        }

        const response = await axios({ 
          url             : CHECK_AUTH_STATUS_ENDPOINT,
          method          : 'POST',
          headers         : {
                              authorization: `Bearer ${csst}`
                            },
          withCredentials : true,
        });

        /* If verification is successful, extract firebase custom token */
        const {
          status    = false, 
          frbToken  = null
        } = response.data;
        
        /* If verification was unsuccessful, return false and show error message */
        if (!status || !frbToken) {
          let {message} = response.data
          console.log(message)

          return false;
        }

        /* Sign in user via Firebase */
        await Firebase.auth().signInWithCustomToken(frbToken)

      } catch (e) {

        console.error(e)
        return false;
      }

    }

    /* If everything works without erro, return trye */
    return true;
    
}


/**
 * Log out user from firebase. Reset cookies
 */
export const logout = async() => {
  if (!isLoggedIn()) {
    console.log(`Can not log out. User is not logged in.`)
    return false
  }

  try {
    /* Extract CSST cookie */
    const parsedCookie = cookie.parse(document.cookie);
    // console.log(parsedCookie)

    const { csst = null } = parsedCookie;
    /* If there is no CSST cookie, return false */
    if (!csst) {
      return false;
    }

    //Log user out
    const response = await axios({
      url: LOGOUT_ENDPOINT,
      method: 'GET',
      headers: {
        authorization: `Bearer ${csst}`
      },
      withCredentials: true,
    });

    const {
      status = false
    } = response.data;

    /* If logout was unsuccessful, return false and show error message */
    if (!status) {
      let { message } = response.data
      console.log(message)

      return false;
    }

    /* Successful logout*/
    await Firebase.auth().signOut()
    return true;

  } catch (e) {

    console.error(e)
    return false;
  }
}

export const checkIfUserExists = async(data) => {
  return await axios({
    url: CHECK_IF_USER_EXISTS,
    method:'GET',
    params: data
  });
}


/* ******************************************** */

export const login = async ({
  email,
  password
}) => {
  const url = LOGIN_ENDPOINT;
  const data = {
    email,
    password
  }
  try {

    const response = await axios({
      url,
      method: 'POST',
      data,
      withCredentials: true

    });
    return response.data;
  } catch (e) {
    const {
      message = "Unexpected problem"
    } = e
    return {
      status: false,
      message
    }
  }
}