import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// import ProgressBar from '../utilities/ProgressBar';
// import Progress from '../utilities/Progress';
import back_next_button from '../utilities/BackButton';
import List from '../list/List';
import MobileProgress from '../mobileProgress/MobileProgress';

import '../../stylesheets/question.css';

import data from './checklistJson';
import Stepper from '../utilities/Stepper';
import Upload from '../upload/Upload';
import UploadConstrained from '../upload/UploadConstrained';


class Checklist extends React.Component {
    constructor(props) {
        super(props);
        const steps = this.getSteps();
        this.state = {
            questions: data.data,
            currentIndex: 0,
            class: "fade-in",
            selected_options: [],
            steplabels: steps, 
        }
    }

    getSteps = () => {
        var result = [];
        data.data.map(function (step) {
            result.push({ step: step.label, completed: false });
            // console.log(step.label);
            return ("success");
        });
        return result;
    }

    handleBack = () => {
        if (this.state.currentIndex - 1 >= 0) {
            this.setState({ class: 'fade-out' })
            var millisecondsToWait = 500;
            setTimeout(() => {
                this.setState({ currentIndex: this.state.currentIndex - 1, class: 'fade-in' })
            }, millisecondsToWait);
        }
    }

    handleNext = () => {
        this.setState({ class: 'fade-out' });
        var millisecondsToWait = 500;
        if (this.state.currentIndex + 1 <= this.state.questions.length) {
            setTimeout(() => {
                var state = this.state;
                state.steplabels[this.state.currentIndex].completed = true;
                this.setState({ state });
                this.setState({ currentIndex: this.state.currentIndex + 1, class: 'fade-in' })
            }, millisecondsToWait);
        } else if(this.state.currentIndex === this.state.questions.length){
            this.handleSubmit();
        }
        else
        {
            this.handleSubmit()
        }
    }

    handleSelection = (e) => {
        this.setState({ class: 'fade-out' });
        var millisecondsToWait = 500;
        setTimeout(() => {
            this.setState({ currentIndex: e, class: 'fade-in' })
        }, millisecondsToWait);
    }

    renderProgressIndicator = () => {
        return (
            <>
                <Container style={{ marginTop: "1rem", marginBottom: "5rem" }}>
                    <Row>
                        <Col xs={1}></Col>
                        <Col xs={10}>
                            <Stepper steps={this.state.steplabels} currentIndex={this.state.currentIndex} handleClick={this.handleSelection} />
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                </Container>
            </>
        )
    }

    renderMobileProgressIndicator = () => {
        return (
            <MobileProgress steps={this.state.steplabels} currentIndex={this.state.currentIndex} handleNext={this.handleNext} handleBack={this.handleBack} handleSelection={this.handleSelection} />      
        )
    }

    handleSubmit = () => {
        // console.log("Finished");
        this.props.onComplete({user_id: this.props.user_id, canvas_id: this.props.canvas_id, questions: this.state.questions});
    }

    setSelected = (data) => {
        this.setState({ selected_options: data });
    }

    setUploads = (data) => {
        this.setState({uploads: data})
        this.handleNext();
    }

    renderQuestion = () => {
        if(this.state.questions[this.state.currentIndex]){
        switch (this.state.questions[this.state.currentIndex].type) {
            case "CHECKLIST":
                if(this.state.steplabels.length === this.state.currentIndex + 1){
                    return (<List questions={this.state.questions[this.state.currentIndex].options} selected_options={this.state.selected_options} title={this.state.questions[this.state.currentIndex].title} onContinue={this.handleSubmit} setSelected={this.setSelected} onBack={this.handleBack} currentIndex = {this.state.currentIndex} totalSteps ={this.state.steplabels.length}/>);
                }else{
                    return (<List questions={this.state.questions[this.state.currentIndex].options} selected_options={this.state.selected_options} title={this.state.questions[this.state.currentIndex].title} onContinue={this.handleNext} setSelected={this.setSelected} onBack={this.handleBack} currentIndex = {this.state.currentIndex} totalSteps ={this.state.steplabels.length}/>);
                }
                
            
            case "UPLOAD-CONSTRAINED":
                return (<UploadConstrained data={this.state.questions[this.state.currentIndex]} onContinue={this.setUploads} onBack={this.handleBack} />);
            
                case "VIDEO-UPLOAD":
                    return (<Upload data={this.state.questions[this.state.currentIndex]} onContinue={this.setUploads} onBack={this.handleBack} />);

            default:
                return (<p>An error occurred</p>);
        }
    }
    }

    render() {
        // console.log(this.props.match.params)
        if (this.props.mobile) {
            return (
                <Container fluid={true} id="question" style={{ marginTop: "2rem" }}>
                    {this.renderMobileProgressIndicator()}
                    <div className={this.state.class} style={{ marginBottom: "2rem" }}>
                        {this.renderQuestion()}
                    </div>
                </Container>
            )
        } else {
            return (
                <Container fluid={true} id="question" style={{ marginTop: "2rem" }}>
                    {this.renderProgressIndicator()}
                    <div className={this.state.class}>
                        {this.renderQuestion()}
                    </div>
                </Container>
            )
        }
    }
}

export default Checklist;