import React from 'react';
import { Container, Col, Row, Dropdown } from 'react-bootstrap';

import rightArrow from '../../images/icons/rightArrow.svg';
import leftArrow from '../../images/icons/leftArrow.svg';
import menu from '../../images/icons/menu.svg';

class MobileProgress extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            steps: []
        }
    }
    onClickHandler = (e) => {
        this.props.handleSelection(e);
        console.log(e)
    }
    render() {
        const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
            return (
                <div
                    // href=""
                    ref={ref}
                    onClick={(e) => {
                        e.preventDefault();
                        onClick(e);
                    }}>
                    {children}
                </div>
            )
        });
        const { currentIndex, steps } = this.props;
        console.log(currentIndex)
        console.log(steps)
        let filledDots = Array.from(Array(currentIndex + 1).keys())
        let unfilledDots = Array.from(Array(steps.length - currentIndex - 1).keys())
        filledDots = filledDots.map(i => <div style={{ "height": "8px", "width": "8px", "borderRadius": "8px", backgroundColor: "#5714ac" }}></div>)
        unfilledDots = unfilledDots.map(i => <div style={{ "height": "8px", "width": "8px", "borderRadius": "8px", backgroundColor: "#5714ac", opacity: "0.1" }}></div>)

        return (
            <Container style={{ "marginBottom": "2rem" }}>
                <Row style={{ "justifyContent": "space-evenly", alignItems: "center" }}>
                    {filledDots}
                    {unfilledDots}
                </Row>
            </Container>
        )
    }
}

export default MobileProgress;