import React from 'react';
import { Row, Container, Col, Button, Form } from 'react-bootstrap';
import Checkbox from '../checkbox/Checkbox';
import '../../stylesheets/buttons.scss'


class List extends React.Component {
    constructor(props) {
        super(props);
        var preselected_options = [];
        this.props.questions.map((option) => {
            if (option.completed) {
                return (preselected_options.push(option));
            }
            return (null);
        });
        this.state = {
            selected_options: preselected_options
        }
    }

    checkIfSelected = (data) => {

        for (let i = 0; i < this.props.selected_options.length; i++) {
            const current = this.props.selected_options[i];
            if (current.id === data.id) {
                data.completed = true;
                return true;
            }

        }
        return false;
    }

    selectOption = (data) => {
        if (!this.checkIfSelected(data)) {
            this.props.setSelected([...this.props.selected_options, data]);
        }
        else {
            let temp = this.props.selected_options.filter(x => x.id !== data.id)
            data.completed = false;
            // this.props.selected_options = temp;
            this.props.setSelected(temp);
        }
    }

    render() {
        return (
            <Container>
                <Row className="justify-content-md-center">
                    <h2 className="col-12 question-h2" style={{ textAlign: "center" }}>
                        {this.props.title}
                    </h2>
                    <Container style={{ marginBottom: "1rem", marginTop: "2rem" }}>
                        <Form>
                            <Col xs={12} className="justify-content-left">
                                {this.props.questions.map((value) => {
                                    return (
                                        <Checkbox data={value} selectOption={this.selectOption} selected={this.checkIfSelected(value)} />
                                    )
                                })}
                            </Col>
                        </Form>
                    </Container>
                    <Col xs={12} sm={{ span: 5 }}>
                        <Row className="justify-content-center">
                            {this.props.currentIndex !== 0 && <Button variant = "light" className="back_next_button" onClick={this.props.onBack}>
                                Back
                            </Button>}
                            {this.props.currentIndex + 1 < this.props.totalSteps && <Button variant = "light" className="back_next_button" onClick={this.props.onContinue} >
                                Next
                            </Button>}

                            {this.props.currentIndex + 1 === this.props.totalSteps &&
                                <Button
                                    onClick={this.props.onContinue} variant = "light" className="back_next_button" style={{paddingLeft : "3rem", paddingRight : "3rem"}}>
                                    Complete
                            </Button>}

                        </Row>
                        <Row style = {{marginTop : "4rem"}}></Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default List;