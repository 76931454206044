let API_ENDPOINT;
let GOOGLE_SEARCH_ENDPOINT;
// let INSTA_SEARCH_ENDPOINT;
let FRONTEND_ENDPOINT;
let PROJECT_BACKEND_ENDPOINT;
let UPLOAD_ENDPOINT;
let SHARING_API;
let SHARING_PORTAL_FRONTEND;
let AUTH_ENDPOINT;
let ECOSYSTEM_ENDPOINT
let AUTH_FRONTEND_ENDPOINT

if (process.env.REACT_APP_STAGING) {
    FRONTEND_ENDPOINT = "http://canary.naya.studio";
    API_ENDPOINT = "https://naya-app-backend-staging-ziylmcndpq-ue.a.run.app";
    AUTH_ENDPOINT = "https://stg-auth.backend.naya.studio";
    GOOGLE_SEARCH_ENDPOINT =
        "https://www.googleapis.com/customsearch/v1?cx=017582732796115664878%3Amjnrwzjj1j4&imgColorType=color&searchType=image&key=AIzaSyAM6brFB5bwrhn6DRw-Wj_IendGbvNbVY8&q=";
    UPLOAD_ENDPOINT =
        "https://naya-uploads-staging-ziylmcndpq-ue.a.run.app/upload";
    
    SHARING_API = "https://naya-sharing-backend-ziylmcndpq-ue.a.run.app";
    SHARING_PORTAL_FRONTEND = "https://naya-sharing-portal-ziylmcndpq-ue.a.run.app"
    ECOSYSTEM_ENDPOINT = "https://stg-eco.naya.studio";
    AUTH_FRONTEND_ENDPOINT = 'https://stg-auth.naya.studio'
} else if (process.env.NODE_ENV === "production") {
    FRONTEND_ENDPOINT = "//app.naya.studio";
    API_ENDPOINT = "//api.naya.studio";
    AUTH_ENDPOINT = "https://auth.backend.naya.studio";
    GOOGLE_SEARCH_ENDPOINT =
        "https://www.googleapis.com/customsearch/v1?cx=017582732796115664878%3Amjnrwzjj1j4&imgColorType=color&searchType=image&key=AIzaSyAM6brFB5bwrhn6DRw-Wj_IendGbvNbVY8&q=";
    UPLOAD_ENDPOINT =
        "https://naya-uploads-production-ziylmcndpq-ue.a.run.app/upload";
    
    SHARING_API = "https://naya-sharing-backend-ziylmcndpq-ue.a.run.app";
    SHARING_PORTAL_FRONTEND = "https://sharing.naya.studio"
    ECOSYSTEM_ENDPOINT = 'https://ecosystem.naya.studio'
    AUTH_FRONTEND_ENDPOINT = 'https://auth.naya.studio'
} else {
    FRONTEND_ENDPOINT = "http://localhost:3000";
    API_ENDPOINT = "http://localhost:3005";
    AUTH_ENDPOINT = "http://localhost:3015";
    SHARING_API="http://localhost:3010";
    SHARING_PORTAL_FRONTEND="http://localhost:3002"
    UPLOAD_ENDPOINT =
        "http://localhost:3005/api/upload";
    PROJECT_BACKEND_ENDPOINT =
        "https://naya-project-backend-development-ziylmcndpq-ue.a.run.app";
    GOOGLE_SEARCH_ENDPOINT =
        "https://www.googleapis.com/customsearch/v1?cx=017582732796115664878%3Amjnrwzjj1j4&imgColorType=color&searchType=image&key=AIzaSyAM6brFB5bwrhn6DRw-Wj_IendGbvNbVY8&q=";
    ECOSYSTEM_ENDPOINT = 'http://localhost:3003'
    AUTH_FRONTEND_ENDPOINT = 'http://localhost:3013'
        
}
const LOGIN_AND_REDIRECT_TO_ACCOUNT_PAGE = `${FRONTEND_ENDPOINT}/account`
const CHECK_USER_EXISTS_ENDPOINT=`${API_ENDPOINT}/api/user?email=`;
const LOGIN_ENDPOINT = `${AUTH_ENDPOINT}/login`;
const TOKEN_LOGIN_ENDPOINT = `${API_ENDPOINT}/auth/jwt`;
const REGISTER_ENDPOINT = `${API_ENDPOINT}/auth/register`;
const MARKETPLACE_FURNITURE_ENDPOINT = `${API_ENDPOINT}/api/marketplace/furniture_votes`;
const LEAD_ENDPOINT =`${API_ENDPOINT}/api/lead`;
const GOOGLE_AUTH_LINK = `${API_ENDPOINT}/auth/google?callbackURL=${window.location.protocol}//${window.location.host}`
const GOOGLE_AUTH_LINK_CLOSE_AFTER_LOGIN = `${GOOGLE_AUTH_LINK}/login_and_exit`
const FACEBOOK_AUTH_LINK = `${API_ENDPOINT}/auth/facebook?callbackURL=${window.location.protocol}//${window.location.host}`
const FACEBOOK_AUTH_LINK_CLOSE_AFTER_LOGIN = `${FACEBOOK_AUTH_LINK}/login_and_exit`

const CHECK_AUTH_STATUS_ENDPOINT = `${AUTH_ENDPOINT}/check-status`
const LOGOUT_ENDPOINT = `${AUTH_ENDPOINT}/signout`
const TWITTER_ENDPOINT = `${API_ENDPOINT}/twitter`

export {
    API_ENDPOINT,
    AUTH_ENDPOINT,
    GOOGLE_SEARCH_ENDPOINT,
    FRONTEND_ENDPOINT,
    UPLOAD_ENDPOINT,
    PROJECT_BACKEND_ENDPOINT,
    ECOSYSTEM_ENDPOINT,
    SHARING_API,
    SHARING_PORTAL_FRONTEND,
    CHECK_USER_EXISTS_ENDPOINT,
    LOGIN_ENDPOINT,
    TOKEN_LOGIN_ENDPOINT,
    REGISTER_ENDPOINT,
    MARKETPLACE_FURNITURE_ENDPOINT,
    LEAD_ENDPOINT,
    LOGIN_AND_REDIRECT_TO_ACCOUNT_PAGE,
    GOOGLE_AUTH_LINK,
    GOOGLE_AUTH_LINK_CLOSE_AFTER_LOGIN,
    FACEBOOK_AUTH_LINK,
    FACEBOOK_AUTH_LINK_CLOSE_AFTER_LOGIN,
    CHECK_AUTH_STATUS_ENDPOINT,
    LOGOUT_ENDPOINT,
    TWITTER_ENDPOINT,
    AUTH_FRONTEND_ENDPOINT
};
