import iro from '@jaames/iro'

/**
 * Sets the color variables to change theme
 * @param {string} color - 'tomato' | 'purple'; default == 'purple' 
 */
const changeDocumentTheme = async( color ) => {
  switch (color) {
    case 'dark':
      document.body.style.setProperty("--primary-background", "#2F2F2F")
      document.body.style.setProperty("--secondary-background", "#444444")
      document.body.style.setProperty("--primary-text-color", "#F6F6F6")
      document.body.style.setProperty("--secondary-text-color", "#949494")
      document.body.style.setProperty("--theme-color-with-opacity", "#646464")
      document.body.style.setProperty("--primary-button-background", "#2F2F2F")
      document.body.style.setProperty("--secondary-button-background", "#646464")
      document.body.style.setProperty("--footer-background", "#252525")
      document.body.style.setProperty("--box-shadow", '#555555')
      break;
    case 'light':
      document.body.style.setProperty("--primary-background", "#F6F6F6")
      document.body.style.setProperty("--secondary-background", "#FFFFFF")
      document.body.style.setProperty("--primary-text-color", "#000000")
      document.body.style.setProperty("--secondary-text-color", "rgba(0, 0, 0, 0.4")
      document.body.style.setProperty("--primary-button-background", "#FFFFFF")
      document.body.style.setProperty("--secondary-button-background", "#FFFFFF")
      document.body.style.setProperty("--footer-background", "#F6F6F6")
      document.body.style.setProperty("--box-shadow", 'rgba(0, 0, 0, 0.1)')
      break;
    case 'tomato':
      document.body.style.setProperty("--theme-color-1", "rgba(234, 59, 39, 1)")
      document.body.style.setProperty("--theme-color-2", "rgba(234, 59, 39, 0.3)")
      document.body.style.setProperty("--theme-color-3", "rgba(230, 138, 127, 1)")
      document.body.style.setProperty("--theme-color-with-opacity", "rgba(230, 138, 127, 0.3)")
      document.body.style.setProperty('--theme-color-1-hex', `#ea3b27`)
      break;
    case 'purple':
    default:
      document.body.style.setProperty("--theme-color-1", "rgba(87, 20, 172, 1)")
      document.body.style.setProperty("--theme-color-2", " rgba(87, 20, 172, 0.3)")
      document.body.style.setProperty("--theme-color-3", "rgba(158, 131, 191, 0.1)")
      document.body.style.setProperty("--theme-color-with-opacity", "rgba(158, 131, 191, 0.3)")
      document.body.style.setProperty('--theme-color-1-hex', `#5714ac`)
      break;
  }
}

//in use with iro dependency and color slider
const colorWheelChangeTheme = (color, callback) => {
  const { hsv } = color
  let rgb
  let { h, s } = hsv

  if (s < 60) {
    s = 60
  } 

  rgb = iro.Color.hsvToRgb({ h, s, v: 80 })

  const { r, g, b } = rgb
  const a = 1
  const hex = RGBToHex(r, g, b)

  document.body.style.setProperty("--theme-color-1", `rgba(${r}, ${g}, ${b}, ${a})`)
  document.body.style.setProperty("--theme-color-2", `rgba(${r}, ${g}, ${b}, ${a * .3})`)
  const theme3 = convertTheme2To3({r, g, b, a: a * .3})
  document.body.style.setProperty("--theme-color-3", `rgba(${theme3.r}, ${theme3.g}, ${theme3.b}, ${1})`)
  document.body.style.setProperty("--theme-color-with-opacity", `rgba(${theme3.r}, ${theme3.g}, ${theme3.b}, ${.3})`)
  document.body.style.setProperty('--theme-color-1-hex', `${hex}`)

  callback && callback()
}

//custom algorithm for internal use
//basicallly takes the rgba of theme2 set on a white background
const convertTheme2To3 = (rgba) => {
  const { r, g, b, a } = rgba

  const rgb = {
    r: (1 - a) * 255 + a * r,
    g: (1 - a) * 255 + a * g,
    b: (1 - a) * 255 + a * b
  }

  return rgb
}

const RGBToHex = (r, g, b) => {
  r = parseInt(r).toString(16);
  g = parseInt(g).toString(16);
  b = parseInt(b).toString(16);

  if (r.length === 1)
    r = "0" + r;
  if (g.length === 1)
    g = "0" + g;
  if (b.length === 1)
    b = "0" + b;

  return "#" + r + g + b;
}

export {
  changeDocumentTheme,
  colorWheelChangeTheme
}