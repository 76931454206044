import React from 'react';
import { Container, Col, Spinner, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom'
import logo from '../../../assets/images/brand/naya_logo_symbol.png';
import wordmark from '../../../assets/images/brand/naya_logo_wordmark.png';
import Input from '../input/Input';
import LinksSection from '../links-section/LinksSection';
import { FRONTEND_ENDPOINT } from '../../../constants/secrets';
import {signUpWithEmailPassword, signInWithPopup, signInWithProvider} from '../../../actions/authenticationActions';

class SignupForm extends React.Component{
  state = {
    loading: false, 
    errorMessage: null,
    email:'',
    password:'',
    firstName:'',
    lastName:'', 
    passwordConfirmation:'',
    termsConditions: false
  }

  setLoading = (value) => {
    this.setState({loading:value})
  }

  setErrorMessage = (value) => {
    this.setState({errorMessage: value})
  }

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  onChangeCheckbox = (e) => {
    this.setState({[e.target.name]: e.target.checked})
  }

  onSubmit = async(e) => {
    e.preventDefault();
    const {email, password, passwordConfirmation, termsConditions} = this.state;
    /* check if email and password is entered */
    if (!email.trim() || !password.trim() || !passwordConfirmation.trim())
      return this.setErrorMessage('Email/Password must be set.');

    /* check if passwords match */
    if (password !== passwordConfirmation)
      return this.setErrorMessage('Passwords do not match');

    /* check if passwords match */
    if (!termsConditions)
      return this.setErrorMessage('Please accept the terms and conditions to proceed');

      this.setLoading(true);
      this.setErrorMessage(null);
      try{
        const firebaseUser = await signUpWithEmailPassword(email, password);

        const { user } = firebaseUser

        if (!user || !user.email || !user.uid){
          console.log(user)
          return console.error('error signing up on firebase')
        }

        //add user to mongoDB
        const res = await signInWithProvider({
          name: this.state.firstName,
          last_name: this.state.lastName
        })

        if (!res.status) {
          return console.error('error registering on mongo')
        }

        //used to update react state in app.js after successful sign up
        if (this.props.checkLogin) {
          return this.props.checkLogin()
        }

        this.props.history.push('/')
      }
      catch(error){
        this.setErrorMessage(error.message)
      } finally {
        this.setLoading(false);
      }
  }

  /* function to render signup form */
  renderSignupFormContent = () => {
    const {email, password, passwordConfirmation, firstName, lastName, termsConditions} = this.state;
    return (
      <>
      <Input
        id="email"
        value={email}
        placeholder={"Email"}
        type="email"
        name='email'
        onChange={this.onChange}
      />
      <div style={{display:'flex'}}>
        <Input
          value={firstName}
          placeholder={"First Name"}
          onChange={this.onChange}
          name='firstName'
          containerClasses={['name-container']}
        />
        <Input
          value={lastName}
          placeholder={"Last Name"}
          onChange={this.onChange}
          name='lastName'
          containerClasses={['name-container']}
        />
      </div>
      <Input
        id="password"
        placeholder={"Password"}
        value={password}
        type="password"
        name='password'
        onChange={this.onChange}
      />
      <Input
        id="setPasswordConfirmation"
        placeholder={"Confirm Password"}
        value={passwordConfirmation}
        type="password"
        name='passwordConfirmation'
        onChange={this.onChange}
      />
      <Form.Group>
        <Form.Label>
          <Form.Check name="termsConditions" value={termsConditions} onChange={this.onChangeCheckbox} style={{"display":"inline-block"}}></Form.Check>
          &nbsp; I accept the <a href="https://docs.google.com/document/u/2/d/e/2PACX-1vTov12Yo4MCqPhMYsXgHgngMOTRAyeqNHCfwnEfMVCvZywTrMROtX9wFF9kBaS7XTPR0x6wSQ8Dj-vV/pub">terms and conditions</a></Form.Label>
      </Form.Group>
      <button className="sign-in-button">{this.state.loading ? this.renderLoader() : 'Sign up'}</button>
      </>
    )
  }

  /* function to render naya logo */
  renderLogo = () => (
    <Col xs={12} className="logo-container">
      <Col xs={{ span: 4, offset: 4 }}>
        <img src={logo} alt="logo" className="logo fluid" />
      </Col>
      <Col xs={{ span: 8, offset: 2 }}>
        <img src={wordmark} alt="wordmark" className="wordmark fluid" />
      </Col>
    </Col>
  )

  /* function to render necessary messages - loader, error msg, etc */
  renderMessages = () => {
    const {showSubmitMessage} = this.props;
    const {loading, errorMessage} = this.state;
    return(
      <>
      {
        showSubmitMessage && (
          <Col xs={12}>
            <p style={{"textAlign":"center", "fontFamily":"rand bold"}}>Please login or sign up to submit your idea</p>
          </Col> 
        )
      }
      {
        loading && (
          <div>
            <p className="loading">
            </p>
          </div>
        )
      }
      {
        errorMessage && (
          <div className="error-message">
            {errorMessage}
          </div>
        )
      }
      </>
    )
  }

  /* function renders three links:
  1. ecosystem login
  2. Toggles to login form
  3. forgot password
   */
  renderLinksSection = () => {
    return(
      <LinksSection>
        <div>
          <a
            className="link-ecosystem" 
            href={`${FRONTEND_ENDPOINT}/login-ecosystem`}
            target="_blank"
            rel='noopener noreferrer'
          >
            Are you a designer/maker?
          </a>
        </div>
        <div>
          Don’t have an account ? {' '}
          <span
            className="link"
            onClick={this.props.onLoginLinkClick}
          >
            Login
          </span>
        </div>
        <a href="/" onClick={(e)=> {
          e.preventDefault();
          this.props.onForgotPasswordLinkClick();
        }}>
          Forgot Password?
        </a>
      </LinksSection>
    )
  }

  /* function to render necessary messages - loader, error msg, etc */
  renderSocialSignIn = () => {
    return(
      <>
      <button className="sign-in-button" onClick={async() => {
          await signInWithPopup("google");
        }}
      >Sign In with Google</button>
      <button className="sign-in-button"	onClick={async() => {
          signInWithPopup("facebook");
        }}
      >Sign In with Facebook</button>
      </>
    )
  }

  renderLoader = () => (
    <div>
        <Spinner animation="grow" size="sm" className="loader"/>
        <Spinner animation="grow" size="sm" className="loader"/>
        <Spinner animation="grow" size="sm" className="loader"/>
      </div>
  )

  render(){
    return(
      <Container>
        <Col xs={12} className='form-container'>
          <form className='login-form col-11' onSubmit={this.onSubmit}>
            {
              this.renderLogo()

            }
            {
              this.renderMessages()
            }
            {
              this.renderSignupFormContent()
            }
            {
              this.renderLinksSection()
            }
            {
              this.renderSocialSignIn()
            }
          </form>
        </Col>
      </Container>
    )
  }
}

export default withRouter(SignupForm);