import React from 'react';
import { Modal, Button, Row, Container, Col } from 'react-bootstrap';

class Welcome extends React.Component {
    render() {
        return (
            <Modal {...this.props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                {/* <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header> */}
                <Modal.Body style={{marginTop: "3rem", marginBottom: "3rem"}}>
                    <Container>
                        <Row className="justify-content-center" style={{marginBottom: "1rem"}}>
                            <h4 style={{fontFamily: "bigmoore, serif", fontWeight: 700, "textAlign":"center"}} >Welcome to <br />Naya QA/QC</h4>
                        </Row>
                        <Row xs={6} className="justify-content-center ">
                            <Col xs={10}>
                                <p className="text-center" style={{ fontSize: "0.9rem"}}>
                            
                                If you’ve got your own processes for quality control, great! Keep those procedures going. We just ask that you take a couple minutes to answer a few questions before you send your project out the door. Thanks for your help!
                                </p>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "2rem"}} className="justify-content-center">
                            <Button variant="dark" onClick={this.props.onHide} style={{fontSize: "1.05rem", paddingLeft: "4rem", paddingRight: "4rem", paddingTop: "0.5rem", paddingBottom: "0.5rem", backgroundColor: "#000000"}}>Continue</Button>
                        </Row>
                    </Container>
                </Modal.Body>
                {/* <Modal.Footer>
                </Modal.Footer> */}
            </Modal>
        )
    }
}

export default Welcome;