import React from 'react';
import {Col} from 'react-bootstrap';
import { LOGIN_AND_REDIRECT_TO_ACCOUNT_PAGE } from '../../../constants/secrets';
import LoginForm from '../login-form/LoginForm';
import SignupForm from '../signup-form/SignupForm';
import firebase from '../../../services/firebase';
import {signInWithProvider, isLoggedIn} from '../../../actions/authenticationActions';
import ForgotPassword from '../forgot-password/ForgotPassword';

class FormContainer extends React.Component{
  
  /* function redirecting the user to the account page once login is successful  */
  afterLoginSuccess = () => {
    firebase.auth().onAuthStateChanged(async (user) => {
      console.log("redirecting to account")
      if(user)
      {
        if(user.email)
        {
          await signInWithProvider()
          if (this.props.checkLogin)
            this.props.checkLogin()
        }
      }
      if(isLoggedIn()){
        window.location = LOGIN_AND_REDIRECT_TO_ACCOUNT_PAGE;
      }

    });
  }

  renderAuthForms = () => {
    switch(this.props.formType){
      case 'login':
        return (
          <LoginForm
            afterLoginSuccess={this.afterLoginSuccess}
            onSignupLinkClick={() => this.props.setFormType('signup')} // toggle to signup form
            onForgotPasswordLinkClick={() => this.props.setFormType('forgot-password')} // toggle to forgot password form
          />
        )
      case 'signup':
        return (
          <SignupForm
            checkLogin={this.props.checkLogin}
            onLoginLinkClick={() => this.props.setFormType('login')} // toggle to signup form
            onForgotPasswordLinkClick={() => this.props.setFormType('forgot-password')} // toggle to forgot password form
          />
        )
      case 'forgot-password':
        return(
          <ForgotPassword
          onSignupLinkClick={() => this.props.setFormType('signup')} // toggle to signup form
          onLoginLinkClick={() => this.props.setFormType('login')} // toggle to signup form
          ></ForgotPassword>
        )
      default:
        break;
    }
  }

  render(){
    return(
      <Col id='forms-container' xs={12} xl={5}>
      {
        this.renderAuthForms()
      }
      </Col>
    )
  }
}

export default FormContainer;