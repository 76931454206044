import {AUTH_ENDPOINT} from '../constants/secrets';

const CHECK_AUTH_STATUS_ENDPOINT  =`${AUTH_ENDPOINT}/status`;
const LOGIN_ENDPOINT              =`${AUTH_ENDPOINT}/login`;
const PROVIDER_LOGIN_ENDPOINT     =`${AUTH_ENDPOINT}/register`;
const GET_USER_ENDPOINT           =`${AUTH_ENDPOINT}/user`;
const LOGOUT_ENDPOINT             =`${AUTH_ENDPOINT}/logout`;
const CHECK_IF_USER_EXISTS        =`${AUTH_ENDPOINT}/user/check-email`;

export {
  CHECK_AUTH_STATUS_ENDPOINT,
  LOGIN_ENDPOINT,
  GET_USER_ENDPOINT,
  PROVIDER_LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT,
  CHECK_IF_USER_EXISTS
}