import React from 'react';
// import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button, ProgressBar } from 'react-bootstrap';
import { UPLOAD_ENDPOINT } from "../../constants/secrets";
import Gallery from "react-photo-gallery";
import axios from "axios";
import VideoThumbnail from 'react-video-thumbnail';

import { css } from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";

import Dropzone from "react-dropzone";
// importing icons
import documentIcon from "../../images/icons/document.svg";
import audio from "../../images/icons/audio.svg";
import video from "../../images/icons/video.svg";
import close from "../../images/icons/close.svg";
import cloudUpload from "../../images/icons/cloud_upload_icon.png";
import camera from "../../images/icons/camera.svg";
import '../../stylesheets/buttons.scss';

import Option from './Option';

// import {addAnswerToCanvas, updateAnswerToCanvas} from '../../actions/canvasActions';

// css for dropzone
const style = {
  container: {
    cursor: "pointer",
    border: "1px dashed black",
    borderRadius: "5px",
    // backgroundColor: "black",
    padding: "50px",
    // minHeight: "500px",
    textAlign: "center",
  },
};

const spinnerStyle = css`
  margin-left: 50%;
  transform: translate(-50%, 0);
`;

class Upload extends React.Component {
  state = {
    uploads: [],
    gallery: [],
    otherDocuments: [],
    uploading: false,
    edit: false,
    uploadPercentage : 0
  }

  // checkIfQuestionExists = () => {
  //   let questions = this.props.canvas.onboarding_questions;
  //   for(let i=0;i<questions.length;i++){
  //     if(questions[i].id===this.props.question.id){
  //       return questions[i];
  //     }
  //   }
  //   return null;
  // }

  componentDidMount() {
    // let data = this.checkIfQuestionExists();
    // if(data!==null){
    //   this.setState({uploads:data.uploads, edit:true});
    //   for(let i=0;i<data.uploads.length;i++){
    //     if (["jpg","jpeg","gif","png","svg","raw","webp","tiff","psd","bmp","heif",].includes(data.uploads[i].ext)) {
    //       this.getMeta(data.uploads[i].link, this.addImageUpload);
    //     } 
    //     else {
    //       this.setState({otherDocuments: [...this.state.otherDocuments,data.uploads[i]],});
    //     }
    //   }
    // }
  }


  handleUpload = (fileData) => { 
    const formData = new FormData();
    const file = fileData.file;
    formData.append("file", file, file.name);
    const UPLOAD_FILE_DATA_URI = UPLOAD_ENDPOINT;
    console.log("File:::::", file)
    const options = {
      onUploadProgress: (progressEvent) => {
      //   const {loaded, total} = progressEvent;
      //   let percent = Math.floor( (loaded * 100) / total )
      //   console.log( `${loaded}kb of ${total}kb | ${percent}%` );

      //   if( percent < 100 ){
      //     this.setState({ uploadPercentage: percent })
      //   }
      // }

      

      setInterval();
    }}
   
    axios
      .post(UPLOAD_FILE_DATA_URI, formData, {
        onUploadProgress: (progressEvent) => {
          this.setState({uploading : true})
          setInterval(() => {
           
            if(this.state.uploadPercentage < 80){
              this.setState({uploadPercentage : this.state.uploadPercentage +1});
            }
          }, 700)
        }
      })
      .then((response) => {
        let temp = this.state.uploads;
        temp.push(response.data);
        this.props.data["uploads"] = temp;
        while(this.state.uploadPercentage < 100){
          this.setState({uploadPercentage : this.state.uploadPercentage +1});
          if(this.state.uploadPercentage === 100){
            setTimeout(()=> {
              this.setState({uploading : false, uploadPercentage : 0});
            }, 2000)
          }
        }
        // Checking if the uploaded file is image or not
        // if it is an image, adding it to the gallery
        if (["jpg", "jpeg", "gif", "png", "svg", "raw", "webp", "tiff", "psd", "bmp", "heif",].includes(response.data.ext)) {
          this.getMeta(response.data.link, this.addImageUpload);
        }
        else {
          this.setState({ otherDocuments: [...this.state.otherDocuments, { id: response.data.id, link: response.data.link, name: response.data.name, ext: response.data.ext, },], });
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({ uploading: false });
      });
  };

  // handling files upload
  handleFilesAdd = (files) => {
    files.forEach((file) => {
      const fileData = { file: file, uploaded: false };
      this.handleUpload(fileData);
    });
  };

  // Adding the uploaded images to the gallery
  addImageUpload = (url, width, height) => {
    this.setState({ gallery: [...this.state.gallery, { src: url, height: height, width: width },], });
  };

  removeImage = (url) => {
    let temp = this.state.uploads.filter(y => y.link !== url);
    let tempGallery = this.state.gallery.filter(x => x.src !== url);
    this.setState({ gallery: tempGallery, uploads: temp })
  };

  getMeta = (url, callBack) => {
    var img = new Image();
    img.onload = function () {
      callBack(url, this.width, this.height);
    };
    img.src = url;
  };

  
  handleSubmit = () => {
    // this.props.data["uploads"] = this.state.gallery
    this.props.onContinue();
  }

  render() {
    return (
      <Container>
        <Row className="justify-content-md-center">
          <Col className='media-drop-wrapper' xs={12} style={{ padding: "1rem", margin: 'auto' }}>
            <Row className="justify-content-center">
              <Col xs={4}>
                <Dropzone onDrop={this.handleFilesAdd}>
                  {({ getRootProps, getInputProps }) => (
                    <Container style={style.container} {...getRootProps()}>
                      {this.state.gallery.length > 0 && (
                        <Gallery
                          photos={this.state.gallery}
                          direction={"column"}
                          columns={2}
                          renderImage={this.imageRenderer}
                        ></Gallery>

                      )}
                      {this.state.uploading && <ProgressBar now={this.state.uploadPercentage} active label={`${this.state.uploadPercentage}%`} style = {{backgroundColor : "#434343", width : "100%", marginBottom : "1rem"}} />}
                      <div style={{ display: "flex", flexWrap: "wrap", }} >
                        {this.state.otherDocuments.map((doc, index) => (
                          <div className='other-documents-item'>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
                              <video className="photo-item__video" muted="" preload="none" controls style = {{height : "100%", width : "100%", marginLeft : "0px"}}>
                                  <source src={doc.link} type="video/mp4" />
                                </video> 
                          </div>
                          <div style={{ height: 20, whiteSpace: "break-spaces", textOverflow: "ellipsis", overflow: "hidden", textAlign: "center", }}>
                            {doc.name}
                          </div>
                            </div>
                          ))}
                      </div>
                    <Row className='justify-content-md-center'>
                      <input {...getInputProps()} />
                      {!this.state.uploading && (
                          <Col>
                            <p style={{ fontSize: "1.2rem", fontWeight: "400", marginBottom: "2rem" }}>

                            </p>
                            <Col xs={{ span: 6, offset: 3 }} >
                              <img src={camera} className={"img-fluid"} alt="upload-icon"></img>
                            </Col>
                            <p style={{ fontSize: "0.9rem", fontWeight: "400", marginBottom: "2rem" }}>
                              Take a video<br /> or
                        </p>

                            <Button style={{
                              marginTop: "-1.5rem", textTransform: "none", border: "2px solid var(--theme-color-1)",
                              backgroundColor: "#fff",
                              borderRadius: "2rem", color: "#000", fontFamily: "rand light"
                            }} className="upload_files">
                              Upload Video
                        </Button>
                          </Col>
                        )}
                    </Row>
                    </Container>
                  )}
                </Dropzone>
              </Col>
            </Row>
          </Col>
        <Col xs={12} sm={{ span: 5 }} >
          <Row className="justify-content-center" style={{ marginTop: "1rem" }}>
            
            {this.props.currentIndex !== 0 && <Button onClick={this.props.onBack} className="back_next_button" variant="light" >
              Back
                </Button>}
            <Button onClick={this.handleSubmit} className="back_next_button" variant="light" >
              Next
              </Button>

          </Row>
        </Col>
        </Row>
      </Container >
    )
  }
}

export default Upload;
