import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import qs from 'qs'
import { FRONTEND_ENDPOINT, ECOSYSTEM_ENDPOINT, AUTH_FRONTEND_ENDPOINT } from '../../constants/secrets'
import { Dropdown } from 'react-bootstrap';
import user_icon from '../../assets/images/icons/user.png'
// import plus_icon from '../../assets/images/icons/plus.png'
// import signup_white from '../../assets/images/icons/signup_white.svg'
import login_white from '../../assets/images/icons/login_white.svg';
import {
  isLoggedIn,
  checkStatus,
  logout,
  getUser
} from '../../actions/authenticationActions';

const logoutFunction = async () => {
  await logout();
  this.checkLogin();
}

const LoginSignupButtons = ({
  setFormType
}) => {
  // console.log("status : ", status)
  const [ecosystemRedirect] = useState(() => qs.stringify({
    redirect_uri: ECOSYSTEM_ENDPOINT
  }))
  const history = useHistory()

  const handleLoginFormType = (e, type) => {
    console.log("clicked on login")
    e.preventDefault()
    e.stopPropagation()
    if (window.location.href.includes('ecosystem')) {
      if (type === 'login')
        window.open(`${AUTH_FRONTEND_ENDPOINT}?${ecosystemRedirect}`, '_self')
      if (type === 'signup')
        window.open(`${AUTH_FRONTEND_ENDPOINT}?${ecosystemRedirect}`, '_self')
    } else {
      setFormType(type)
      history.push('/login')
    }
  }



  const isEcosystem = () => {
    return window.location.href.includes('ecosystem');
  }

  return (
    <>
      {/* <div onClick={(e) =>handleLoginFormType(e,'signup')}><img src={isEcosystem() ? signup_white: plus_icon} width="40" alt='sign up button' style={{padding:isEcosystem() && '0.5rem'}}/><p>Sign Up</p></div> */}
      {/* <div onClick={(e) => handleLoginFormType(e,'login')}><img src={isEcosystem() ? login_white: user_icon} width="40" alt='login button' style={{padding:isEcosystem() && '0.5rem'}} /></div> */}
      <div><img src={isEcosystem() ? login_white: user_icon} width="40" alt='login button' style={{padding:isEcosystem() && '0.5rem'}} /></div>
    </>
  )

}

export default LoginSignupButtons