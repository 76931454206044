let QA_FLOW = {
    data: 
    [
        {
            id: 'looks',
            type: "CHECKLIST",
            title: 'How does it look?',
            label: 'Appearance',
            options: [
                {
                    text: "Colors, materials and finish match approved sample & rendering",
                    id: "looks_color",
                    completed: false
                },
                {
                    text: "No visible cracks, holes or dents in surfaces",
                    id: "looks_surface",
                    completed: false
                },
                {
                    text: "Joints and seams are clean",
                    id: "looks_joints",
                    completed: false
                },
                {
                    text: "No visible paint drips, bubbles, scuffs and stains",
                    id: "looks_finish",
                    completed: false
                },
                {
                    text: "Grain direction / material pattern is oriented correctly",
                    id: "looks_material",
                    completed: false
                },
                {
                    text: "No laminates or veneers are peeling up to corners or edges",
                    id: "looks_edgework",
                    completed: false
                },
                {
                    text: "Drawer face / door face gaps are even",
                    id: "looks_faces",
                    completed: false
                },
                {
                    text : "Furniture piece is level to the ground, not uneven",
                    id : "looks_level",
                    completed : false
                }
            ]
        },
        
        {
            id: 'feel',
            type: "CHECKLIST",
            title: "How does it feel?",
            label: "Texture",
            options: [
                {
                    text: "Finish is dry, not tacky",
                    id: "feel_finish",
                    completed: false
                },
                {
                    text: "No visible cracks, holes or dents in surface",
                    id: "feel_surface",
                    completed: false
                }, 
                {
                    text: "Joints and seams are flush",
                    id: "feel_joints",
                    completed: false
                },
                {
                    text: "There are no uneven or rough patches",
                    id: "feel_patches",
                    completed: false
                }
            ]
        },
        {
            id: 'size',
            type: "CHECKLIST",
            title: "Is it the right size?",
            label: "Dimension",
            options: [
                {
                    text: "Dimensions fully match shop drawings to approved tolerance",
                    id: "size_dimensions",
                    completed: false
                },
                {
                    text: "Weight will meet any specified requirements",
                    id: "size_weight",
                    completed: false
                }
            ]
        },
        {
            id: 'moving_parts',
            type: "CHECKLIST",
            title: "Do moving parts work?",
            label: "Moving Parts",
            options: [
                {
                    text: "Doors open in specified direction",
                    id: "moving_parts_doors",
                    completed: false
                },
                {
                    text: "All hardware function smoothly and appropriately",
                    id: "moving_parts_joints",
                    completed: false
                },
                //Need clarity of the next one
                {
                    text: "No visible paint drips, scuffs or stains",
                    id: "moving_parts_finish",
                    completed: false
                }
            ]
        },
        {
            id: "assembly_disassembly",
            type: "CHECKLIST",
            title: "Assembly and Dissassembly",
            label: "Assembly",
            options: [
                {
                    text: "Each complete unit has all necessary components and hardware accounted for",
                    id: "assembly_dissassembly_hardware",
                    completed: false
                },
                {
                    text: "Each finished piece has been appropriately assembled or disassembled for packaging and transit",
                    id: "assembly_dissassembly_success",
                    completed: false
                }
            ]
        },
        {
            id: "looks-uploads",
            type: "UPLOAD-CONSTRAINED",
            title: "Add a few photos",
            label: "Add Photos",
            options: [
                {
                    label: "Front View",
                    id: "front_view"
                },
                {
                    label: "Rear View",
                    id: "rear_view"
                },
                {
                    label: "Right Side View",
                    id: "right_side_view"
                },
                {
                    label: "Left Side View",
                    id: "left_side_view"
                },
                {
                    label: "Top View",
                    id: "top_view"
                },
                {
                    label: "All Corners",
                    id: "all_corners"
                }
                
            ]
            
        },
        {
            id: "upload",
            type: "VIDEO-UPLOAD",
            title: "Detail Photos",
            label: "Add Video"
        },
       
        {
            id: "packaging",
            type: "CHECKLIST",
            title: "Secure and Organized Packaging",
            label: "Packaging",
            options: [
                {
                    text: "All documentation necessary to help customer with assembly is included in the package",
                    id: "packaging_documentation",
                    completed: false
                },
                {
                    text: "Each component is securely packed and padded to prevent damage in transit",
                    id: "packaging_packed",
                    completed: false
                },
                {
                    text: "All components are clearly labeled",
                    id: "packaging_labelled",
                    completed: false
                },
                {
                    text: "Final weight does not exceed shipping rate limitations",
                    id: "packaging_weight",
                    completed: false
                },
                {
                    text: "Shipping insurance was purchased",
                    id: "packaging_insurance",
                    completed: false
                }, {
                    text: "Tracking number is acquired",
                    id: "packaging_tracking",
                    completed: false
                }
            ]
        },
        
        
    ]
}

export default QA_FLOW;