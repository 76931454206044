import React from 'react';

import '../../stylesheets/stepper.scss';

class Stepper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            steps: [],
            completedSteps: []
        }
    }
    
    render() {
        const { steps, currentIndex, handleClick } = this.props;
        // console.log(currentIndex, steps);
        const showSteps = steps.map((step, index) => {
            const stepChange = () => {
                handleClick(index);
            }
            return (
                <>
                    <div className="step-wrapper" >
                        {index!== 0 ? <div className="divider-line-before"></div> : <></>}
                        <div className={`step-number ${index === currentIndex ? "step-number-active" : step.completed ? "step-number-completed" : ""} `} onClick={stepChange} style={{"cursor": "pointer"}} ></div>
                        <div className={`step-label ${index === currentIndex ? "step-label-active" : step.completed ? "step-label-completed" : ""} `}>{step.step}</div>
                        {index !== steps.length - 1 ? <div className="divider-line-after"></div> : <></>}
                    </div>
                </>
            )
        })
        return (

            <div className="stepper-container">
                <div className="stepper-wrapper">
                    {showSteps}
                </div>
            </div>
        )
    }
}

export default Stepper;
