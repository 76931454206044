import React from 'react';
import { Row, Col } from 'react-bootstrap';

import checkedBox from '../../images/icons/checkedBox.svg';
import hoverBox from '../../images/icons/hoverBox.svg';
import emptyBox from '../../images/icons/emptyBox.svg';
class Checkbox extends React.Component {
    state = {
        mouseOver: false
    }
    handleSelect = (e) => {
        e.preventDefault()
        this.props.selectOption(this.props.data)
    }

    render() {
        const { data, selected } = this.props;
        let button;
        if (selected) {
            button = <img style={{ paddingTop: "auto", paddingBottom: "auto" }} alt="" src={checkedBox} height="20px" />
        } else if (this.state.mouseOver) {
            button = <img style={{ paddingTop: "auto", paddingBottom: "auto" }} alt="" src={hoverBox} height="20px" />
        } else {
            button = <img style={{ paddingTop: "auto", paddingBottom: "auto" }} alt="" src={emptyBox} height="20px" />
        }
        return (
            <Row onClick={this.handleSelect} onMouseOver={() => this.setState({ mouseOver: true })} onMouseLeave={() => this.setState({ mouseOver: false })} >
                <Col xs>
                    <p>{data.text}</p>
                </Col>
                <Col className="justify-content-end" xs={1}>
                    {button}
                </Col>
            </Row>
        )
    }
}

export default Checkbox;