import React from 'react';
// import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button, ProgressBar } from 'react-bootstrap';
import { UPLOAD_ENDPOINT } from "../../constants/secrets";
import Gallery from "react-photo-gallery";
import axios from "axios";

import { css } from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";

import Dropzone from "react-dropzone";
// importing icons
import documentIcon from "../../images/icons/document.svg";
import audio from "../../images/icons/audio.svg";
import video from "../../images/icons/video.svg";
import close from "../../images/icons/close.svg";
import cloudUpload from "../../images/icons/camera.svg";
import Upload from '../upload/Upload';
import '../../stylesheets/buttons.scss'

import Option from './Option';

// import {addAnswerToCanvas, updateAnswerToCanvas} from '../../actions/canvasActions';

// css for dropzone
const style = {
  container: {
    cursor: "pointer",
    border: "1px dashed black",
    borderRadius: "5px",
    // backgroundColor: "black",
    padding: "50px",
    // minHeight: "500px",
    textAlign: "center",
  },
};

const spinnerStyle = css`
  margin-left: 50%;
  transform: translate(-50%, 0);
`;

class UploadConstrained extends React.Component {
  state = {
    uploads: [],
    gallery: [],
    otherDocuments: [],
    uploading: false,
    isUploading: {},
    edit: false,
    uploadPercentage : 0
  }

  handleUpload = (fileData, i) => {
    const formData = new FormData();
    const file = fileData.file;
    formData.append("file", file, file.name);
    const UPLOAD_FILE_DATA_URI = UPLOAD_ENDPOINT;
    this.setState({ uploading: true, isUploading: { ...this.state.isUploading, [i]: true } })
    axios
      .post(UPLOAD_FILE_DATA_URI, formData, {
        onUploadProgress: (progressEvent) => {
          this.setState({uploading : true})
          setInterval(() => {
           
            if(this.state.uploadPercentage < 80){
              this.setState({uploadPercentage : this.state.uploadPercentage +1});
              console.log('This will run every second!');
            }
          }, 10)
        }})
      .then((response) => {
        let temp = this.state.uploads;
        temp.push(response.data);
        this.setState({ uploads: temp, uploading: false, isUploading: { ...this.state.isUploading, [i]: false } })
        while(this.state.uploadPercentage < 100){
          this.setState({uploadPercentage : this.state.uploadPercentage +1});
          if(this.state.uploadPercentage === 100){
            setTimeout(()=> {
              this.setState({uploading : false, uploadPercentage : 0});
            }, 2000)
          }
        }
        
        // Checking if the uploaded file is image or not
        // if it is an image, adding it to the gallery
        if (["jpg", "jpeg", "gif", "png", "svg", "raw", "webp", "tiff", "psd", "bmp", "heif",].includes(response.data.ext)) {
          this.getMeta(response.data.link, this.addImageUpload, i);
        }
        else {
          this.setState({ otherDocuments: [...this.state.otherDocuments, { id: response.data.id, link: response.data.link, name: response.data.name, ext: response.data.ext, },], });
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({ uploading: false, isUploading: { ...this.state.isUploading, [i]: false } })
      });
  };

  // handling files upload
  handleFilesAdd = (files, i) => {
    console.log(files)
    files.forEach((file) => {
      const fileData = { file: file, uploaded: false };
      this.handleUpload(fileData, i);
    });
  };

  // Adding the uploaded images to the gallery
  addImageUpload = (url, width, height, i) => {
    let tempGallery = this.state.gallery
    let currentData = this.filterGallery(tempGallery, "index", i)
    console.log(currentData)
    if (currentData.length > 0) {
      console.log(tempGallery)

      let newData = { ...currentData[0], "src": url, width, height }
      console.log(currentData[0])
      tempGallery[tempGallery.findIndex(el => el === currentData[0])] = newData

      this.setState({ gallery: tempGallery })
    }
    else {
      this.setState({ gallery: [...this.state.gallery, { index: i, src: url, height: height, width: width }] });
    }
  };

  removeImage = (url) => {
    let temp = this.state.uploads.filter(y => y.link !== url);
    let tempGallery = this.state.gallery.filter(x => x.src !== url);
    this.setState({ gallery: tempGallery, uploads: temp })
  };

  getMeta = (url, callBack, i) => {
    var img = new Image();
    img.onload = function () {
      callBack(url, this.width, this.height, i);
    };
    img.src = url;
  };

  imageRenderer = ({ left, top, key, photo }) => {
    return (
      <Option
        onRemove={this.removeImage}
        value={photo.src}
        // selected={this.state.selected_options.has(photo.src)}
        margin={"2px"}
        left={left}
        top={top}
        photo={photo}
        key={key}
      ></Option>
    );
  };

  handleSubmit = () => {
    let uploads = []
    for (let i = 0; i < this.state.gallery.length; i++) {
      let index = this.state.gallery[i]["index"]
      let upload = { ...this.state.gallery[i], "label": this.props.data.options[index]["label"] }
      uploads.push(upload)
    }

    this.props.data["uploads"] = uploads

    console.log("this.props.data", this.props.data)
    this.props.onContinue();
  }

  filterGallery = (array, key, value) => {
    return array.filter(el => el[key] === value)
  }

  render() {

    let { title, options } = this.props.data
    return (
      <Container>
        <Row className="justify-content-md-center">
          <h2 className="col-12 question-h2" style={{ textAlign: "center" }}>
            {title}
          </h2>
          {options.map((option, i) => {
            let upload = this.filterGallery(this.state.gallery, "index", i)[0]
            return <Col className='media-drop-wrapper' xs={12} lg={{ span: 4 }} style={{ padding: "1rem", margin: 'auto' }}>
              <Dropzone onDrop={(files) => this.handleFilesAdd(files, i)} multiple={false}>
                {({ getRootProps, getInputProps }) => (

                  <Container style={!upload ? style.container : { ...style.container, "border": "none", "padding": "0px", "overflow": "hidden" }} {...getRootProps()}>
                    <input {...getInputProps()} />
                    
                    <Row className='justify-content-md-center' style={{ "padding": "0px", "display": "flex", "justifyContent": "center", "alignItems": "end" }}>
                      {upload && <img src={upload['src']} alt={option["label"]} style={{ height: "300px", width: "auto" }} />}
                      {upload && <p style={{ color: "white", backgroundColor: "black", "padding": "0.5rem", position: "absolute", "borderRadius": "5px", "cursor": "pointer" }}>{option["label"]} - Click to Change</p>}
                    </Row>
                    {!upload &&
                      <Row className='justify-content-md-center'>
                        { this.state.isUploading[i] ? ( 
                          <ProgressBar now={this.state.uploadPercentage} active label={`${this.state.uploadPercentage}%`} style = {{backgroundColor : "#434343", width : "100%", marginBottom : "1rem"}} />
                        ) : (
                            <Col>
                              <p>
                                {option["label"]}
                              </p>

                              <Col xs={{ span: 6, offset: 3 }} >
                                <img src={cloudUpload} className={"img-fluid"} alt="upload-icon"></img>
                              </Col>

                              <p>
                                Take a picture <br></br>or
                        </p>

                              <Button style={{
                                marginTop: "0rem", border: "2px solid var(--theme-color-1)",
                                backgroundColor: "#fff",
                                borderRadius: "2rem", color: "#000", textTransform: "none", fontFamily: "rand light", paddingLeft: "2rem", paddingRight: "2rem"
                              }} className="upload_files">
                                Select File
                        </Button>
                            </Col>
                          )}
                      </Row>}
                  </Container>
                )}
              </Dropzone>
            </Col>
          })}
          <Col xs={12} sm={{ span: 5 }}>
            <Row className="justify-content-center" style = {{marginTop : "2rem"}}>
              {this.props.currentIndex !== 0 && <Button onClick={this.props.onBack} className="back_next_button">
                Back
                </Button>}

              <Button onClick={this.handleSubmit} className="back_next_button">
                Next
              </Button>

            </Row>
            <Row style = {{marginTop : "4rem"}}></Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default UploadConstrained;

// /**
//  *
//  * @param {*} state
//  * @param {*} ownProps
//  * Maps the Redux state to component props
//  */
// const mapStateToProps = (state, ownProps) => {

//   return {
//     canvas:state.canvas
//   };
// };

// /**
// *
// * @param {*} dispatch
// * Maps redux actions to component props
// */
// const mapDispatchToProps = (dispatch) => {
//   return {
//     addAnswerToCanvas: (data) => dispatch(addAnswerToCanvas(data)),
//     updateAnswerToCanvas: (data) => dispatch(updateAnswerToCanvas(data)),
//   };
// };

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter((Upload)));