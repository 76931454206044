import React from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import Check from '../../images/icons/selected_purple.svg';

import '../../stylesheets/question.css';
import '../../stylesheets/buttons.scss';


class Completion extends React.Component {
    render() {
        return (
            <Container fluid={true} id="question" style={{ marginTop: "10%" }}>
                <Container style={{ marginTop: "auto", marginBottom: "auto" }}>
                    <Row className="justify-content-md-center">
                        <h2 className="col-12 question-h2" style={{ textAlign: "center" }}>
                            Thank you - Your project is under review
                            {/* {this.props.title} */}
                        </h2>
                        <Container className="justify-content-center" style={{ width: "80%", paddingTop: "10px", marginBottom: "2rem", marginTop: "2rem" }}>
                            <Row className="justify-content-center">
                                <Image height="200px" src={Check} />
                            </Row>
                        </Container>
                        <Col xs={12}>
                            <Row className="justify-content-center">
                                <Button
                                    onClick={() => window.location = "https://app.naya.studio/login"}
                                    variant = "light"
                                    className = "back_next_button"
                                >
                                    Done
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>
        )
    }
}

export default Completion;