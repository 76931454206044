import React from 'react';
import axios from 'axios';

import Checklist from './components/checklist/Checklist';
import Welcome from './components/welcome/Welcome';
import Completion from './components/completion/Completion';
import Landing from './components/landing/Landing'
import { getUserDetails } from './helpers/authentication';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Container, Row, Form, Navbar } from 'react-bootstrap';
import LoginSignupButtons from './utilities/side-nav/LoginSignup';
import { Dropdown } from 'react-bootstrap';
// import { useHistory } from 'react-router-dom'
import naya_symbol from './assets/images/brand/naya_logo_symbol.png'
import naya_symbol_white from './assets/images/brand/logo_white.svg'
import user_icon from './assets/images/icons/user.png'
// import ScrollToTop from './utilities/scroll-to-top/scroll-to-top'
import wheel from './utilities/color-theme/wheel.svg'
import cookie from 'cookie'
import firebase from "./services/firebase";
import qs from 'qs'

import './App.scss';
import { ColorSelectThemeButton } from './utilities/color-theme/colorSelectThemeButton';
import { colorWheelChangeTheme } from './utilities/color-theme/changeDocumentTheme';
import { changeDocumentTheme } from './utilities/color-theme/changeDocumentTheme';
import ColorWheel from './utilities/color-theme/colorWheel'
import {
  isLoggedIn,
  checkStatus,
  logout,
  getUser
} from './actions/authenticationActions';
import Login from './components/login/Login';
import { NAYA_APP_BACKEND } from './constants'
import { AUTH_FRONTEND_ENDPOINT } from './constants/secrets'
// const history = useHistory()

const config = {
  headers: {
    "Access-Control-Allow-Origin": "*"
  }
}




axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
class App extends React.Component {
  state = {
    welcomed: true,
    completed: false,
    windowWidth: window.innerWidth,
    //required for Navbar
    loggedIn: false,
    cookieHash: null,
    profile_pic: user_icon,
    colorTheme: 'purple',
    showNewsLetterModal: false,
    showWheel: false,
    loginFormType: 'login',
    redirect : window.location.href
    // tweets: []
  }

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  }

  onComplete = (data) => {
    this.setState({ completed: true });
    const url = NAYA_APP_BACKEND;
    console.log(JSON.stringify(data));
    axios.post(url, JSON.stringify(data), config).then(res => {
      console.log("response", res);
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.changePageTheme();
    console.log("web redirect: ", this.state.redirect)
    const parsedCookie = cookie.parse(document.cookie);
    const { csst = null } = parsedCookie;
    
    if (!csst)
      firebase.auth().signOut()

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const currentUser = await getUser()

        if (!currentUser.status) {
          console.error('No user in database found with log in.')
          // console.log(user)
          return
        }

        this.checkLogin()
        return

      } else {
        this.checkLogin()
        await checkStatus()
      }
    });

    setTimeout(
      () => this.setState({ showNewsLetterModal: true }),
      7000
    );
  }

  /* Function to logout the user */
  logout = async () => {
    await logout();
    this.checkLogin();
  }


  /* Function to check if the user is logged into the system */
  checkLogin = async () => {
    this.setState({
      loggedIn: isLoggedIn()
    })
  }

  renderColorWheel = () => (
    <ColorWheel
      setModal={this.setColorWheel}
      modalShow={this.state.showWheel}
      onChange={colorWheelChangeTheme}
      color={this.state.colorTheme}
      callback={this.changeTheme}
    />
  )
  setFormType = (type = 'login') => {
    this.setState(prev => ({
      ...prev,
      loginFormType: type
    }))
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize);
  }

  changePageTheme = () => {
    // toggling the dark and light theme as per the landing screen accessed by the user
    if (this.isEcosystem()) {
      changeDocumentTheme("dark");
      changeDocumentTheme("tomato");
      this.setState({ colorTheme: 'tomato' })
    }
    else {
      changeDocumentTheme("light")
    }
  }

  welcomedFunc = () => {
    console.log("Welcomed")
    this.setState({ welcomed: true });
  }

  isPhoneScreen = () => {
    return /iPhone|Android/i.test(navigator.userAgent);
  }

  isEcosystem = () => {
    return window.location.href.includes('ecosystem');
  }
  /**
  * 
  * @param {string} color 'purple' | 'tomato'
  */
  changeTheme = (color) => {
    this.setState((prev) => ({
      ...prev,
      colorTheme: color,
    }))
  }

  /**
  * True to show Modal, False to close Modal
  * @param {boolean} value 
  */
  setColorWheel = (value) => {
    console.log("color wheel: ", value)
    this.setState({
      showWheel: value
    })

  }

  renderTopNavContent = () => {
    const { loggedIn } = this.state;

    let logged_in_icon = user_icon
    if (loggedIn) {
      let user = getUserDetails()
      if (user && user["profile_pic"]) {

        logged_in_icon = user["profile_pic"]
      }
    }

    return (
      <>
        <Container>
          {this.renderColorWheel()}
          <Row>
            <Navbar expand="lg" className="nav">
            </Navbar>
            <Navbar.Brand onClick={() => window.location.href = "/"}>
              <img
                src={this.isEcosystem() ? naya_symbol_white : naya_symbol}
                width="40"
                style={{ marginLeft: "0rem" }}
                className="d-inline-block align-top naya-logo"
                alt="Naya Studio Logo"
              />
            </Navbar.Brand>
            <Form inline className="top-nav-rhs" style={{ display: 'flex' }}>
              {
                !this.isPhoneScreen() && <a href={this.isEcosystem() ? "/" : "/ecosystem"} className="redirect-link" style={{ alignSelf: 'flex-start', padding: '0.7rem 1rem', fontSize: '0.9rem' }}>{this.isEcosystem() ? "Are you a client?" : "Are you a designer/maker?"}</a>
              }
              <div className='theme-button-container' >
                <ColorSelectThemeButton color='purple' active={this.state.colorTheme} changeTheme={this.changeTheme} />
                <ColorSelectThemeButton color='tomato' active={this.state.colorTheme} changeTheme={this.changeTheme} />
                <ColorSelectThemeButton color='wheel' wheel={wheel} active={this.state.colorTheme} changeTheme={this.changeTheme} setColorWheel={() => { console.log("World"); this.setColorWheel(true) }} />
                <LoginSignupButtons
                    setFormType={this.setFormType}
                  />
                  <Dropdown>

                    <Dropdown.Toggle variant="light" id="dropdown-basic" style={{ backgroundColor: "#fff", border: "0px #fff" }}>
                    
                    </Dropdown.Toggle>
                    {loggedIn ? <Dropdown.Menu>
                      <Dropdown.Item href="/account">My Account</Dropdown.Item>
                      <Dropdown.Item onClick={() => this.logout()}>Signout</Dropdown.Item>
                    </Dropdown.Menu> : <Dropdown.Menu>
                        <Dropdown.Item onClick ={()=>{                     
                          window.open(`/login`, '_self')                          
                        }}>Login</Dropdown.Item>
                      </Dropdown.Menu>}
                  </Dropdown>
              </div>
            </Form>
          </Row>
        </Container>
      </>
    )
  }


  render() {
    return (
      <>
        <Container
          id='root-container'
          className='vh-100 d-flex flex-column content-wrapper'
          fluid
        >

          <Router>
            <Row style={{ marginBottom: "2rem" }}>
              {this.renderTopNavContent()}
            </Row>
            <Row>
              <Switch>
                <Route path="/:canvas_id/:user_id" render={(props) => <ContentRender {...props} welcomed={this.state.welcomed} completed={this.state.completed} welcomedFunc={this.welcomedFunc} onComplete={this.onComplete} windowWidth={this.state.windowWidth} />} />
                {/* Testing Redirect */}
                <Route
                  path='/login'
                  component={() =>
                    <Login setFormType={this.setFormType} formType={this.state.loginFormType} checkLogin={this.checkLogin} redirectUrl={this.state.redirect}/>
                  }
                />
                <Route path="/">
                  <Landing></Landing>
                </Route>

                {/* <Redirect from="/" to="/5e46b70a6f192698cefbe2e6/5dace2bb7459a15eb1e8bb1b" /> */}
              </Switch>
            </Row>
          </Router>
        </Container>
      </>
    )
  }
}

function ContentRender(props) {
  const { welcomed, completed, welcomedFunc, onComplete, windowWidth } = props;
  const canvas_id = props.match.params.canvas_id;
  const user_id = props.match.params.user_id;
  if (!welcomed) {
    return (<Welcome show={!welcomed} onHide={welcomedFunc} />)
  }
  if (completed) {
    return (<Completion />)
  }
  else {
    return (<Checklist className="top-nav-rhs" onComplete={onComplete} mobile={!(windowWidth > 1000)} canvas_id={canvas_id} user_id={user_id} />);

  }
}

export default App;
