import React from 'react';
import { withRouter } from 'react-router-dom'
import HeaderText from '../../utilities/header-text/HeaderText';
import {Container, Row} from 'react-bootstrap';
import './Login.scss';
import FormContainer from './form-container/FormContainer';
import {isLoggedIn} from '../../actions/authenticationActions';


class Login extends React.Component{
  componentDidMount(){
    // Redirecting to getting started screen if already logged in
    if(isLoggedIn()){
      this.props.history.push("/");
    }
  }

  getTitle = () => {
    switch(this.props.formType){
      case 'login':
        return 'Log in'
      case 'signup':
        return 'Sign up'
      case 'forgot-password':
        return 'Forgot Password'
      default:
        return ''
    }
  }

  render(){
    const {formType, setFormType, checkLogin} = this.props;
    return(
      <div id='login-page' style = {{marginTop : "2rem"}}>
        <HeaderText 
          title={this.props.formType === 'login' ? 'Log in' : 'Sign up'}
          subtitle='Track the progress'
        ></HeaderText>
        <Container>
          <Row className="justify-content-center" >
            {/* Rendering login/signup form */}
            <FormContainer formType={formType} setFormType={setFormType} checkLogin={checkLogin}></FormContainer>
          </Row>
			</Container>
      </div>
    )
  }
}

export default withRouter(Login);