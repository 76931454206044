import React from 'react';
import {Col} from 'react-bootstrap'
import './HeaderText.scss';

class HeaderText extends React.Component{
  render(){
    return(
      <Col xs={12} className="header-text">
        <h2>{this.props.title}</h2>
        {/* <h4>{this.props.subtitle}</h4> */}
    </Col>
    )
  }
}

export default HeaderText;