import React from 'react';
import { Modal, Button, Row, Container, Col } from 'react-bootstrap';

class Landing extends React.Component {
    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <h1 style={{"width":"100%", "textAlign":"center", "marginTop":"2rem"}}>Welcome!</h1>
                        <p style={{"width":"100%","textAlign":"center"}}>To access the QC app, please head to the canvas on ecosystem, and click on 'Submit for Quality Control'</p>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Landing;